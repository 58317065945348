import { Configure, Index, InstantSearch } from "react-instantsearch";
import { useSearchIndex } from "../SearchContextProvider";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import {
  CARDS_PER_ROW,
  COURSE,
  DEFAULT_SEARCH_CONFIG,
  PROGRAM,
} from "../constants";
import getFilters from "../utils/getFilters";
import getPublicClient from "@/lib/algolia/getPublicClient";
import NoResulsProductList from "./NoResulsProductList";

function NoProductFoundAlert({
  productType,
}: {
  productType: "courses" | "programs";
}) {
  return (
    <Alert className="py-6 px-4 rounded-xl">
      <AlertTitle className="text-base font-bold">
        No {productType} were found to match your search query.
      </AlertTitle>
      <AlertDescription>
        Check out some popular {productType} below.
      </AlertDescription>
    </Alert>
  );
}

const searchClient = getPublicClient();
const configForSearch = {
  ...DEFAULT_SEARCH_CONFIG,
  hitsPerPage: CARDS_PER_ROW,
  filters: getFilters(),
};

const SuggestedCourses = (searchIndex: string) => (
  <div>
    <h3 className="text-lg">Popular courses</h3>
    <NoProductFoundAlert productType="courses" />
    <Index indexName={searchIndex} indexId="promotedCourses">
      <Configure
        {...configForSearch}
        facetsRefinements={{ product: [COURSE] }}
      />
      <NoResulsProductList />
    </Index>
  </div>
);

const SuggestedPrograms = (searchIndex: string) => (
  <div>
    <div>
      <h3 className="text-lg">Popular programs</h3>
      <NoProductFoundAlert productType="programs" />
      <Index indexName={searchIndex} indexId="promotedPrograms">
        <Configure
          {...configForSearch}
          facetsRefinements={{ product: [PROGRAM] }}
        />
        <NoResulsProductList />
      </Index>
    </div>
  </div>
);

function NoResults() {
  const { searchIndex } = useSearchIndex();

  return (
    <InstantSearch searchClient={searchClient} indexName={searchIndex}>
      {SuggestedCourses(searchIndex)}
      {SuggestedPrograms(searchIndex)}
    </InstantSearch>
  );
}

export function NoResultsCourses() {
  const { searchIndex } = useSearchIndex();

  return (
    <InstantSearch searchClient={searchClient} indexName={searchIndex}>
      {SuggestedCourses(searchIndex)}
    </InstantSearch>
  );
}
export function NoResultsPrograms() {
  const { searchIndex } = useSearchIndex();

  return (
    <InstantSearch searchClient={searchClient} indexName={searchIndex}>
      {SuggestedPrograms(searchIndex)}
    </InstantSearch>
  );
}

export default NoResults;
