import { useSearchParams } from "next/navigation";
import { useMemo } from "react";

const useHandleSearchParams = () => {
  const params = useSearchParams();

  const searchParams = useMemo(() => {
    const paramsArray: [string, string][] = Array.from(params.entries());

    const paramsObj: { [key: string]: string[] } = {};
    paramsArray.forEach(([key, value]: [string, string]) => {
      if (paramsObj[key]) {
        paramsObj[key].push(value);
      } else {
        paramsObj[key] = [value];
      }
    });

    return paramsObj;
  }, [window.location.search]);

  const currentUrlParams: string = window.location.search;

  const setSearchParam = (key: string, value: string) => {
    const newParams = new URLSearchParams(currentUrlParams);
    newParams.append(key, value);

    const newQuery = newParams.toString();
    const newUrl = `${window.location.pathname}${newQuery ? `?${newQuery}` : ""}`;
    window.history.replaceState(null, "", newUrl);
  };

  const removeSearchParam = (key: string, value: string) => {
    const newParams = new URLSearchParams(currentUrlParams);
    const allValues = newParams.getAll(key);
    newParams.delete(key);
    allValues.forEach((paramValue) => {
      if (paramValue !== value) {
        newParams.append(key, paramValue);
      }
    });

    const newQuery = newParams.toString();
    const newUrl = `${window.location.pathname}${newQuery ? `?${newQuery}` : ""}`;
    window.history.replaceState(null, "", newUrl);
  };

  const clearSearchParams = () => {
    const newUrl = `${window.location.pathname}`;
    window.history.replaceState(null, "", newUrl);
  };

  return {
    searchParams,
    setSearchParam,
    clearSearchParams,
    currentUrlParams,
    removeSearchParam,
  };
};

export default useHandleSearchParams;
