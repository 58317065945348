import React from "react";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { cn } from "@/lib/utils";
import ModalButton from "@/components/Search/SearchFilterModal";
import { useCurrentRefinements } from "react-instantsearch";
import useRefinementExcludedAttributes from "../hooks/useRefinementExcludedAttributes";

function MobileShowAllFilters() {
  const excludedAttributes = useRefinementExcludedAttributes();
  const { items } = useCurrentRefinements({
    excludedAttributes: excludedAttributes,
  });
  const filtersCount = items.reduce(
    (total, item) => total + item.refinements.length,
    0,
  );

  return (
    <div className="flex bg-putty-300 p-1">
      <ModalButton triggerClassName="w-full">
        <button className="flex justify-between items-center m-0 h-10 w-full">
          <small className="m-0">
            All Filters {filtersCount > 0 && `(${filtersCount} selected)`}
          </small>
          <div>
            <ChevronDownIcon
              className={cn(
                "h-6 w-6 shrink-0 text-brand font-bold transition-transform duration-200 text-gray-700 color-gray-700",
              )}
            />
          </div>
        </button>
      </ModalButton>
    </div>
  );
}

export default MobileShowAllFilters;
