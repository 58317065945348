"use client";

import React from "react";

import { ImagesListItemProps, LinkListProps } from "../types";
import Image from "next/image";

export default function Images({ heading = "", subjectMap }: LinkListProps) {
  if (subjectMap === undefined || subjectMap.length === 0) {
    return null;
  }

  const halfMap = Math.ceil(subjectMap.length / 2);

  const getSubjectFilter = (subject: ImagesListItemProps) => {
    const params = new URLSearchParams({ subject: subject.query });
    return (
      <div key={subject.label} className="w-52">
        <a
          className="flex gap-3"
          key={subject.label}
          href={`/search?${params.toString()}`}
        >
          <Image
            src={subject.src}
            alt={subject.label}
            width={80}
            height={80}
            layout="fixed"
            className="w-20 h-20"
          />
          <span className="font-semibold text-wrap text-balance place-content-center grow">
            {subject.label}
          </span>
        </a>
      </div>
    );
  };

  return (
    <div className="fullwidth pb-8 mb-8 pl-0">
      {heading && (
        <h2 className="mt-0 mb-5 md:mb-6 text-3xl md:text-4xl">{heading}</h2>
      )}

      <div className="flex flex-wrap gap-3">
        {subjectMap.map((subject) => getSubjectFilter(subject))}
      </div>
    </div>
  );
}
