"use client";
import { CohesionProps } from "@/components/Cohesion/types";
import { pageTrackingObjectUpdate } from "@/utils/tagular/helpers";
import { useEffect } from "react";
import { useNeedsNewPageViewOnChange } from "@/utils/tagular/needsNewPageView";

export default function CohesionConfig({ pagetype }: CohesionProps) {
  useNeedsNewPageViewOnChange();
  useEffect(() => {
    pageTrackingObjectUpdate(pagetype, true);
  }, [pagetype]);

  return <></>;
}
