/**
 * Determine if an HTML Element is effectively hidden.
 *
 * This checks display, visibility and opacity.
 *
 * NOTE: During SSR or in Headless/Browser-less mode this always returns false.
 *
 * @param ele The element you wish to examine.
 */
export function isElementHidden(ele: HTMLElement | null): boolean {
  if (typeof window === "undefined" || !ele) return false;
  try {
    while (ele) {
      const style = window.getComputedStyle(ele);

      if (style.display === "none") {
        return true;
      } else if (
        style.visibility === "hidden" ||
        style.visibility === "collapse"
      ) {
        return true;
      } else if (parseFloat(style.opacity) === 0) {
        return true;
      }

      ele = ele.parentElement;
    }
  } catch (_) {
    /* no-op */
  }

  return false;
}
