import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  Dispatch,
} from "react";

type State = {
  searchIndex: string;
  resultCount: number;
  isResultTabView?: boolean;
};

type Action =
  | { type: "SET_RESULT_COUNT"; payload: number }
  | { type: "ADD_TO_RESULT_COUNT"; payload: number }
  | { type: "SET_SEARCH_INDEX"; payload: string }
  | { type: "SET_IS_RESULT_TAB_VIEW"; payload: boolean };

const SearchContext = createContext<{
  state: State;
  dispatch: Dispatch<Action>;
}>({
  state: { searchIndex: "", resultCount: 0 },
  dispatch: () => null,
});

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_RESULT_COUNT":
      return { ...state, resultCount: action.payload };
    case "ADD_TO_RESULT_COUNT":
      return { ...state, resultCount: state.resultCount + action.payload };
    case "SET_SEARCH_INDEX":
      return { ...state, searchIndex: action.payload };
    case "SET_IS_RESULT_TAB_VIEW":
      return { ...state, isResultTabView: action.payload };
    default:
      return state;
  }
};

type SearchContextProviderProps = {
  children: ReactNode;
  searchIndex: string;
};

export const SearchContextProvider = ({
  children,
  searchIndex,
}: SearchContextProviderProps) => {
  const initialState: State = {
    searchIndex,
    resultCount: 0,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SearchContext.Provider value={{ state, dispatch }}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = () => useContext(SearchContext);

// For setting and adding to the result count
export const useSearchResultCount = () => {
  const { dispatch, state } = useSearchContext();

  const setResultCount = (count: number) => {
    dispatch({ type: "SET_RESULT_COUNT", payload: count });
  };

  const addToResultCount = (count: number) => {
    dispatch({ type: "ADD_TO_RESULT_COUNT", payload: count });
  };

  return {
    setResultCount,
    addToResultCount,
    resultCount: state.resultCount,
  };
};

// For setting the search index
export const useSearchIndex = () => {
  const { dispatch, state } = useSearchContext();

  const setSearchIndex = (index: string) => {
    dispatch({ type: "SET_SEARCH_INDEX", payload: index });
  };

  return {
    searchIndex: state.searchIndex,
    setSearchIndex,
  };
};

// For setting isResultTabView
export const useIsResultTabView = () => {
  const { dispatch, state } = useSearchContext();

  const setIsResultTabView = (isResultTabView: boolean) => {
    dispatch({ type: "SET_IS_RESULT_TAB_VIEW", payload: isResultTabView });
  };

  return {
    isResultTabView: state.isResultTabView,
    setIsResultTabView,
  };
};
