import { useSearchResultCount } from "../SearchContextProvider";
import { useEffect } from "react";
import { useSearchParams } from "next/navigation";
import { QUERY_URL_PARAM } from "../constants";
import { useInstantSearch } from "react-instantsearch";
interface SearchResultCountProps {
  resultsTitle?: string;
}
function SearchResultCount({ resultsTitle }: SearchResultCountProps) {
  const { resultCount, setResultCount } = useSearchResultCount();
  const searchParams = useSearchParams();
  const { status } = useInstantSearch();
  const queryParam = searchParams.get(QUERY_URL_PARAM);
  const queryResultsLabel = resultsTitle ? resultsTitle : queryParam;

  useEffect(() => {
    setResultCount(0);
    // disabling rule since setResultCount is a function from the hook that will not change
    // adding it can cause the count to work incorrecly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={status !== "idle" ? "invisible" : ""}>
      <h1 className="text-2xl">
        {resultCount} results{" "}
        {queryResultsLabel ? `for "${queryResultsLabel}"` : null}
      </h1>
    </div>
  );
}

export default SearchResultCount;
