"use client";
import { FILTER_CATEGORIES } from "../constants";
import SearchFilterItem from "./SearchFilterItem";

function SearchFilters() {
  return (
    <div className="m-0">
      <h5 className="text-sm mt-9 mb-6 border-gray-700">Refine your search</h5>
      <div className="flex flex-col gap-y-8">
        {FILTER_CATEGORIES.map((filterCat) => {
          return (
            <SearchFilterItem filterCategory={filterCat} key={filterCat} />
          );
        })}
      </div>
    </div>
  );
}

export default SearchFilters;
