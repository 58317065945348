import { ProductType as CourseType } from "@/lib/course/types";

const courseTypes: string[] = [
  CourseType.COURSE,
  CourseType.EXECUTIVE_EDUCATION,
  CourseType.BOOTCAMP,
];

const isCourseType = (productType: string): boolean =>
  courseTypes.includes(productType);

export default isCourseType;
