export const productTypeTranslationMap: Record<string, string> = {
  "Bachelor's Degree": "Licenciatura",
  "Boot Camp": "Programa intensivo",
  "Certificate Program": "Programa de certificación",
  Course: "Curso",
  "Doctoral Program": "Programa de doctorado",
  "Offered on Emeritus": "Disponible en Emeritus",
  "Executive Education": "Educación ejecutiva",
  "Licensure Program": "Programa de licenciatura",
  "Master's Degree": "Título de maestría",
  "MicroBachelors®": "MicroBachelors®",
  "MicroMasters®": "MicroMasters®",
  "Professional Certificate": "Certificado profesional",
  "Offered through Shorelight": "Disponible en Shorelight",
  XSeries: "XSeries",
};

export function getTranslatedProductType(
  productType: string,
  locale: string = "en",
): string {
  if (locale === "es" && productTypeTranslationMap[productType]) {
    return productTypeTranslationMap[productType];
  }
  return productType;
}
