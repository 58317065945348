import { useRefinementList } from "react-instantsearch";
import AccordionContent from "@/components/AccordionContent";
import {
  FACET_FETCH_LIMIT,
  FILTER_CATEGORIES_NAMES_MAP,
  LEARNING_TYPE_FILTER_SUBTITLE,
  BOOT_CAMP,
} from "../constants";
import useHandleSearchParams from "@/hooks/useHandleSearchParams";
import getSortedRefinementItems from "../utils/getSortedRefinementItems";
import { useSearchResultCount } from "../SearchContextProvider";
import { useState } from "react";
import SearchIcon from "@/components/icons/Search";
import CloseIcon from "@/components/icons/Close";
import getUserLocation from "@/lib/location/getUserLocationFromClient";

const ModalAccordionItem = ({ filterCategory }: { filterCategory: string }) => {
  const { setSearchParam, removeSearchParam } = useHandleSearchParams();
  const { setResultCount } = useSearchResultCount();
  const [searchQuery, setSearchQuery] = useState("");
  const location = getUserLocation();
  const { items, refine } = useRefinementList({
    attribute: filterCategory,
    sortBy: ["count:desc"],
    showMore: false,
    escapeFacetValues: true,
    limit: FACET_FETCH_LIMIT,
  });

  const handleRefinementClick = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setResultCount(0);
    refine(event.target.value);
    if (event.target.checked) {
      setSearchParam(filterCategory, event.target.value);
    } else {
      removeSearchParam(filterCategory, event.target.value);
    }
  };

  const handleClearSearch = () => {
    setSearchQuery("");
  };

  const filteredItems = getSortedRefinementItems(items).filter((item) =>
    item.label.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  return (
    <AccordionContent
      title={FILTER_CATEGORIES_NAMES_MAP[filterCategory]}
      rootClassName="px-0"
      contentClassName="relative -mx-5"
      itemClassName="border-b border-gray-200"
      triggerClassName="bg-transparent pt-0 font-normal text-[1.125rem]"
      iconClassName="text-gray-700 color-gray-700"
      childrenClassName="p-0"
    >
      <div className="px-5">
        <div className="w-full mb-3 relative">
          <input
            type="search"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="select-none w-full px-5 py-3 border outline-none focus:outline-none focus:border-gray-200 focus-visible:ring-0 focus-visible:ring-offset-0 text-gray-600 border-gray-200 text-base pr-12"
          />
          <div className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400">
            {searchQuery ? (
              <button
                onClick={handleClearSearch}
                className="hover:text-gray-600"
              >
                <CloseIcon />
              </button>
            ) : (
              <SearchIcon />
            )}
          </div>
        </div>
        {filteredItems.length > 0 ? (
          filteredItems
            .filter((item) =>
              location === "US" ? true : item.label !== BOOT_CAMP,
            )
            .map((item) => (
              <div key={item.label} className="py-1 px-5">
                <div className="py-1 flex justify-start items-start">
                  <input
                    type="checkbox"
                    id={item.value}
                    value={item.value}
                    checked={item.isRefined}
                    onChange={handleRefinementClick}
                    className="border-2 mr-2 border-gray-700 min-w-4 h-5 w-5 cursor-pointer"
                  />
                  <div className="flex justify-start items-start flex-col">
                    <label
                      htmlFor={item.value}
                      className="text-gray-700 flex items-center mb-0 leading-5"
                    >
                      {item.label}
                    </label>
                    <label className="text-xs text-gray-700">
                      {filterCategory === "learning_type" &&
                        LEARNING_TYPE_FILTER_SUBTITLE[
                          item.value as keyof typeof LEARNING_TYPE_FILTER_SUBTITLE
                        ]}
                    </label>
                  </div>
                </div>
              </div>
            ))
        ) : (
          <div className="pb-3 px-5 text-gray-600">No options found</div>
        )}
      </div>
    </AccordionContent>
  );
};

export default ModalAccordionItem;
