export const createIdFromLabel = (label: string) => {
  return label.replace(/\s/g, "-").toLowerCase();
};

export const createLabelFromId = (id: string) => {
  return id.replace(/-/g, " ");
};

export const makeSafeAttributeString = (label: string) => {
  const regex = /[^a-z0-9_]/g;
  return label.toLowerCase().replace("'", "").replace(regex, "-");
};
