"use client";
import ProductCard from "@/components/ProductCard";
import { useHits } from "react-instantsearch";
import { FormatSubType } from "@/constants/tagular/main";
import { SearchResult } from "../types";

function NoResulsProductList() {
  const { items: hits, results } = useHits<SearchResult>();

  return (
    <div className="overflow-x-auto">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 py-4">
        {hits.map((hit, i) => (
          <div key={hit.objectID} className="flex justify-center">
            <ProductCard
              name={hit.title}
              href={hit.marketing_url.replace(
                /^https?:\/\/(www\.)?edx\.org/,
                "",
              )}
              imageUrl={hit.card_image_url || ""}
              type={hit.learning_type?.at(0) as string}
              partnerName={hit.partner?.at(0) as string}
              partnerLogoUrl={hit.owners?.at(0)?.logoImageUrl as string}
              className="w-full"
              location="search-results"
              subjects={hit.subject?.at(0)}
              index={hit.__position - 1}
              formatSubtype={FormatSubType.HorizontallyStacked}
              objectId={hit.objectID}
              queryId={results?.queryID}
              productSource={hit.product_source}
              product={hit.product}
              resultLevel="level"
              uuid={hit.uuid}
              parentTitle={hit.title}
              activeRunKey={hit.active_run_key}
              resultsIndex={results?.index}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default NoResulsProductList;
