import { REFINEMENT_EXCLUDED_ATTRIBUTES } from "../constants";
import { useIsResultTabView } from "../SearchContextProvider";

const useRefinementExcludedAttributes = () => {
  const { isResultTabView } = useIsResultTabView();
  if (!isResultTabView) {
    return REFINEMENT_EXCLUDED_ATTRIBUTES;
  } else {
    return REFINEMENT_EXCLUDED_ATTRIBUTES.filter((excludedItem) => {
      return excludedItem !== "product";
    });
  }
};

export default useRefinementExcludedAttributes;
