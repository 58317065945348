import { PLACEHOLDER_FILTER_COUNT } from "../constants";

function SearchFiltersLoadingPlaceholder() {
  return (
    <div className="w-full">
      {Array.from({ length: PLACEHOLDER_FILTER_COUNT }).map((_, index) => {
        return (
          <div
            className="bg-putty-light h-[20px] w-[100px] mb-8"
            key={index}
          ></div>
        );
      })}
    </div>
  );
}

export default SearchFiltersLoadingPlaceholder;
