"use client";
import * as React from "react";

import { LinkListProps } from "../types";

export default function Dropdown({ contentId, children }: LinkListProps) {
  const [openDetails, setOpenDetails] = React.useState<boolean>(false);

  const articlesCount = React.Children.count(children);

  if (articlesCount <= 3) {
    return <>{children}</>;
  }

  return (
    <>
      <div
        className="border-primary border-2 w-full flex justify-between align-items-center p-3 cursor-pointer"
        onClick={() => setOpenDetails((val) => !val)}
      >
        <button
          type="button"
          className="text-primary no-underline hover:text-primary"
          aria-expanded={openDetails}
          aria-controls={contentId ?? "none"}
        >
          Click to display all articles ({articlesCount})
        </button>
        <span className="block">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            focusable="false"
            aria-hidden="true"
          >
            {openDetails ? (
              <path d="m7 14 5-5 5 5H7Z" fill="currentColor"></path>
            ) : (
              <path d="m7 10 5 5 5-5H7Z" fill="currentColor"></path>
            )}
          </svg>
        </span>
      </div>
      {openDetails && (
        <div className="border-primary border-2 w-full border-t-0 p-3">
          {children}
        </div>
      )}
    </>
  );
}
