"use client";

import { usePagination } from "react-instantsearch";
import { ReadonlyURLSearchParams } from "next/navigation";
import { ChevronRightIcon, ChevronLeftIcon } from "@radix-ui/react-icons";
import { RESULT_PAGE_URL_PARAM } from "../constants";

interface SearchPaginationProps {
  searchParams: ReadonlyURLSearchParams;
}
function SearchPagination({ searchParams }: SearchPaginationProps) {
  const { pages, currentRefinement, refine, nbPages, isFirstPage, isLastPage } =
    usePagination({
      padding: 2,
    });
  const currentUrl = window.location.pathname + window.location.search;
  const isInSearchPage = currentUrl.includes("/search");

  const setPageLink = (page: number) => {
    const params = new URLSearchParams(Array.from(searchParams.entries()));
    params.delete(RESULT_PAGE_URL_PARAM);
    params.set(RESULT_PAGE_URL_PARAM, String(page + 1));
    if (!isInSearchPage) {
      //hard reload, if the user is looking at search results from
      //other pages that are not /search, they should be redirected to search
      //when clicking on a new page
      window.location.href = `search?${params.toString()}`;
    } else {
      window.history.pushState({}, "", `?${params.toString()}`);
    }
  };

  //it can happen that the current page refinement is changed by facet filtering
  //so the url stops matching the refinement
  //This only needs to run when the user is in /search
  if (
    isInSearchPage &&
    currentRefinement + 1 !== Number(searchParams.get(RESULT_PAGE_URL_PARAM))
  ) {
    setPageLink(0);
  }

  if (nbPages < 2) {
    return null;
  }

  const handleClick = (page: number) => {
    setPageLink(page);
    if (isInSearchPage) {
      refine(page);
    }
  };

  const baseClasses =
    "px-3 py-1 mr-0 text-sm hover:bg-secondary-400 hover:text-white";
  const activeClasses = "bg-primary text-white border-double border-4";

  const upTo4Pages = () => {
    return (
      <>
        {pages.map((page) => (
          <button
            key={page}
            onClick={() => handleClick(page)}
            className={`${baseClasses} ${currentRefinement === page ? activeClasses : ""}`}
          >
            {page + 1}
          </button>
        ))}
      </>
    );
  };

  const firstThreePages = () => {
    return (
      <>
        {pages.slice(0, 3).map((page) => (
          <button
            key={page}
            onClick={() => handleClick(page)}
            className={`${baseClasses} ${currentRefinement === page ? activeClasses : ""}`}
          >
            {page + 1}
          </button>
        ))}
        <span>...</span>
        <button
          key={nbPages - 1}
          onClick={() => handleClick(nbPages - 1)}
          className={`${baseClasses} ${currentRefinement === nbPages ? activeClasses : ""}`}
        >
          {nbPages}
        </button>
      </>
    );
  };

  const lastThreePages = () => {
    return (
      <>
        <button
          key={0}
          onClick={() => handleClick(0)}
          className={`${baseClasses} ${currentRefinement === 0 ? activeClasses : ""}`}
        >
          1
        </button>
        <span>...</span>
        {pages.slice(-3).map((page) => (
          <button
            key={page}
            onClick={() => handleClick(page)}
            className={`${baseClasses} ${currentRefinement === page ? activeClasses : ""}`}
          >
            {page + 1}
          </button>
        ))}
      </>
    );
  };

  const middle = () => {
    return (
      <>
        <button
          key={0}
          onClick={() => handleClick(0)}
          className={`${baseClasses} ${currentRefinement === 0 ? activeClasses : ""}`}
        >
          1
        </button>
        <span className="pr-2">...</span>
        <button
          key={currentRefinement}
          onClick={() => handleClick(currentRefinement)}
          className={`${baseClasses} ${activeClasses}`}
        >
          {currentRefinement + 1}
        </button>
        <span className="pl-2">...</span>
        <button
          key={nbPages - 1}
          onClick={() => handleClick(nbPages - 1)}
          className={`${baseClasses} ${currentRefinement === nbPages - 1 ? activeClasses : ""}`}
        >
          {nbPages}
        </button>
      </>
    );
  };

  return (
    <div className="flex flex-row items-center">
      <button
        className="p-2"
        onClick={() => handleClick(currentRefinement - 1)}
        disabled={isFirstPage}
      >
        <ChevronLeftIcon
          className={`${isFirstPage ? "stroke-putty-dark" : "w-4 h-4 text-primary-500 bg-white stroke-primary-500 stroke-1 hover:rounded-full hover:bg-primary-500 hover:stroke-white"}`}
        />
      </button>

      <div className="block md:hidden flex flex-col justify-end items-end text-sm whitespace-nowrap">
        <span>
          {" "}
          {currentRefinement + 1} of {nbPages}{" "}
        </span>
      </div>
      <div className="hidden md:block">
        {nbPages <= 4
          ? upTo4Pages()
          : currentRefinement < 3
            ? firstThreePages()
            : currentRefinement >= nbPages - 3
              ? lastThreePages()
              : middle()}
      </div>

      <button
        className="p-2"
        onClick={() => handleClick(currentRefinement + 1)}
        disabled={isLastPage}
      >
        <ChevronRightIcon
          className={`${isLastPage ? "stroke-putty-dark" : "w-4 h-4 text-primary-500 bg-white stroke-primary-500 stroke-1 hover:rounded-full hover:bg-primary-500 hover:stroke-white"}`}
        />
      </button>
    </div>
  );
}

export default SearchPagination;
