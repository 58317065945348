import { ReadonlyURLSearchParams } from "next/navigation";
import {
  RESULT_TYPES,
  RESULT_TYPE_NONE,
  RESULT_TYPE_URL_PARAM,
} from "../constants";

const getResultTypeFromUrl = (params: ReadonlyURLSearchParams) => {
  const productType = params.get(RESULT_TYPE_URL_PARAM);
  if (productType && RESULT_TYPES.includes(productType.toLowerCase())) {
    return productType.toLowerCase();
  }
  return RESULT_TYPE_NONE;
};

export default getResultTypeFromUrl;
