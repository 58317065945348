import { ReadonlyURLSearchParams, useSearchParams } from "next/navigation";
import {
  FILTER_CATEGORIES,
  QUERY_URL_PARAM,
  RESULT_PAGE_URL_PARAM,
} from "../constants";
import { UiState } from "../types";

export default function useSearchInitialState(
  indexName: string,
  query_string: string = "",
) {
  const nextSearchParams = useSearchParams();
  const searchParamsFromQueryString = new URLSearchParams(
    query_string,
  ) as unknown as ReadonlyURLSearchParams;
  const searchParams = query_string.length
    ? searchParamsFromQueryString
    : nextSearchParams;
  //Algolia has no export for it's UiState type
  const uiState: UiState = {
    [indexName]: {},
  };
  //query
  const query = searchParams.get(QUERY_URL_PARAM);
  if (query) {
    uiState[indexName] = {
      query: query,
    };
  }
  //facets
  const params: { [key: string]: string[] } = {};
  const paramsArray = Array.from(searchParams.entries());
  paramsArray.forEach(([key, value]: [string, string]) => {
    if (params[key]) {
      params[key].push(value);
    } else {
      params[key] = [value];
    }
  });
  let refinements: { [key: string]: any } = {};
  FILTER_CATEGORIES.forEach((facet) => {
    if (params[facet]) {
      refinements[facet] = params[facet];
    }
  });
  uiState[indexName].refinementList = { ...refinements };

  //page
  const pageParam = Number(searchParams.get(RESULT_PAGE_URL_PARAM) || "");
  if (pageParam) {
    uiState[indexName].page = pageParam;
  }

  //unable to pass the initial query here since we have to support multiple indexes
  return uiState;
}
