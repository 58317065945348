"use client";
import { Configure, Index } from "react-instantsearch";
import SearchFilters from "../SearchFilters";
import SearchHits from "../SearchHits";
import { ReadonlyURLSearchParams, useSearchParams } from "next/navigation";
import SearchPagination from "../SearchPagination";
import SearchActiveRefinement from "../SearchActiveRefinement";
import SearchResultCount from "../SearchResultCount";
import { SearchProps } from "../types";
import {
  DEFAULT_SEARCH_CONFIG,
  RESULT_PRODUCT_TYPE_MAP,
  PRODUCT_TYPES_TO_SHOW,
  QUERY_URL_PARAM,
  PRODUCT_TYPE_TITLE_MAP,
  PRODUCT_TO_RESULT_TYPE_MAP,
  CARDS_PER_ROW,
} from "../constants";
import getResultTypeFromUrl from "../utils/getResultTypeFromUrl";
import { useIsResultTabView, useSearchIndex } from "../SearchContextProvider";
import getFilters from "../utils/getFilters";
import MobileShowAllFilters from "../MobileShowAllFilters";
import ProductSearch from "@/components/ProductSearch";
import { useEffect } from "react";

function SearchContent({
  searchConfig = {},
  query_string = "",
  results_title,
}: SearchProps) {
  const nextSearchParams = useSearchParams();
  const { searchIndex } = useSearchIndex();
  const { setIsResultTabView } = useIsResultTabView();
  const searchParamsFromQueryString = new URLSearchParams(
    query_string,
  ) as unknown as ReadonlyURLSearchParams;
  const searchParams = query_string.length
    ? searchParamsFromQueryString
    : nextSearchParams;
  const pageTab = RESULT_PRODUCT_TYPE_MAP[getResultTypeFromUrl(searchParams)];
  const query = searchParams.get(QUERY_URL_PARAM);
  const configForSearch = {
    ...DEFAULT_SEARCH_CONFIG,
    filters: getFilters(),
    ...searchConfig,
  };
  if (pageTab) {
    configForSearch.facetsRefinements = { product: [pageTab] };
  }
  if (query) {
    configForSearch.query = query;
  }
  useEffect(() => {
    if (pageTab) {
      setIsResultTabView(true);
    }
  }, [pageTab]);
  return (
    <>
      <Configure {...configForSearch} />
      <div className="container mt-12 py-5 bg-primary md:hidden">
        <ProductSearch
          placeholder="What do you want to learn?"
          showButtons={false}
        />
      </div>
      <div className="container py-4">
        <div className="md:pt-12 block md:block lg:hidden">
          <MobileShowAllFilters />
        </div>
        <div className="flex sm:justify-end">
          <div className="hidden lg:block lg:w-1/4">
            <SearchFilters />
          </div>
          <div className="overflow-hidden w-full lg:w-3/4">
            <div>
              <SearchResultCount resultsTitle={results_title} />
              <div className="flex justify-between">
                <SearchActiveRefinement />
                {pageTab ? (
                  <SearchPagination searchParams={searchParams} />
                ) : null}
              </div>
            </div>
            <div>
              {pageTab ? (
                <>
                  {/* TAB RESULTS */}
                  <SearchHits level="second" searchParams={searchParams} />
                </>
              ) : (
                PRODUCT_TYPES_TO_SHOW.map((productFacet) => {
                  return (
                    <Index
                      indexName={searchIndex}
                      indexId={productFacet}
                      key={productFacet}
                    >
                      <Configure
                        {...configForSearch}
                        hitsPerPage={CARDS_PER_ROW}
                        facetsRefinements={{ product: [productFacet] }}
                      />
                      <SearchHits
                        title={
                          PRODUCT_TYPE_TITLE_MAP[productFacet] || productFacet
                        }
                        facetForLink={PRODUCT_TO_RESULT_TYPE_MAP[productFacet]}
                        searchParams={searchParams}
                      />
                    </Index>
                  );
                })
              )}
              {pageTab ? (
                <div className="flex justify-center">
                  <SearchPagination searchParams={searchParams} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchContent;
