"use client";
import getPublicClient from "@/lib/algolia/getPublicClient";
import { InstantSearch } from "react-instantsearch";
import SearchContent from "./SearchContent";
import { SearchProps } from "./types";
import { PRODUCT_INDEX_EN } from "./constants";
import { SearchContextProvider } from "./SearchContextProvider";
import useSearchInitialState from "./hooks/useSearchInitialState";

const searchClient = getPublicClient();

function SearchClient(props: SearchProps) {
  const indexName = props.searchIndex || PRODUCT_INDEX_EN;
  const initialSearchState = useSearchInitialState(
    indexName,
    props.query_string,
  );
  return (
    <SearchContextProvider searchIndex={indexName}>
      <InstantSearch
        searchClient={searchClient}
        indexName={indexName}
        initialUiState={initialSearchState}
        future={{
          preserveSharedStateOnUnmount: true,
        }}
      >
        <SearchContent {...props} />
      </InstantSearch>
    </SearchContextProvider>
  );
}

export default SearchClient;
