import { PLACEHOLDER_CARD_COUNT } from "../constants";

function SearchResultsLoadingPlaceholder() {
  return (
    <div className="py-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 flex-1 justify-center">
        {Array.from({ length: PLACEHOLDER_CARD_COUNT }).map((_, index) => {
          return (
            <div
              className="rounded-xl bg-putty-light h-[332px] min-w-[270px] w-full flex items-end border"
              key={index}
            >
              <div className="bg-white w-full p-6 pt-4 rounded-b-xl h-2/4">
                <div className="bg-putty-light mb-6 h-[20px]"></div>
                <div className="bg-putty-light mb-2 h-[95px] pt-4"></div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SearchResultsLoadingPlaceholder;
