"use client";
import { useEffect, useState } from "react";
import CTALink from "@/components/CTA/ctaLink";
import ProductCardList from "@/components/ProductCardList";
import { makeSafeAttributeString } from "@/utils/functions/getIdLabel";
import type { ProductCardListByUUIDClientProps } from "./types";
import { cn } from "@/lib/utils";
import { useLocale } from "next-intl";
import useElementEvents from "@/hooks/eventing/useElementEvents";
import { ElementClicked } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementClicked";
import {
  DEFAULT_USE_IN_VIEW_OPTIONS,
  ElementType,
} from "@/constants/tagular/main";
import { translateVariant } from "@/utils/tagular/dataTranslationMatrices";
import { useInView } from "react-intersection-observer";

export default function ProductCardListByUUIDClient({
  title = "",
  products,
  location,
  card_format_subtype,
  cta_url,
  cta_label,
  className,
  showAllProducts = false,
  cardWrapperClassName = "",
  product_type: productType,
}: ProductCardListByUUIDClientProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const locale = useLocale();

  const expandLabel = locale === "en" ? "Show More" : "Ver más";
  const contractLabel = locale === "en" ? "Show Less" : "Ver menos";

  const webElement: ElementClicked["webElement"] = {
    location,
    position: "1",
    variant: translateVariant(productType ?? ""),
    elementType: ElementType.Link,
    htmlId: "show-more-link",
    text: isExpanded ? "Show Less" : "Show More",
  };

  const { elementClicked, elementViewed } = useElementEvents({
    webElement,
  });
  const { ref: showMoreRef, inView: showMoreInView } = useInView(
    DEFAULT_USE_IN_VIEW_OPTIONS,
  );

  useEffect(() => {
    if (showMoreInView) {
      elementViewed();
    }
  }, [showMoreInView]);

  if (!products || products.length === 0) {
    return null;
  }

  const titleId = makeSafeAttributeString(title);

  const displayedProducts =
    isExpanded || showAllProducts ? products : products?.slice(0, 4);
  const skipEvents = isExpanded ? products?.slice(0, 4) : [];

  const handleShowButtonClick = () => {
    elementClicked();
    setIsExpanded(!isExpanded);
  };

  const ctaLink = () => (
    <CTALink
      html_id="subject-product-link"
      elementType={ElementType.Entry}
      location={location}
      variant={webElement.variant}
      url={cta_url as string}
      label={cta_label as string}
      radius="none"
      size="lg"
    />
  );

  return (
    <>
      <a className="subnav-item" id={titleId}></a>
      <div className={cn("md:mb-12", cardWrapperClassName)}>
        {title && (
          <h3 className="scroll-m-20 pb-2 text-lg font-semibold tracking-tight transition-colors first:mt-0">
            {title}
          </h3>
        )}
        <ProductCardList
          products={displayedProducts}
          shouldScroll={false}
          location={location}
          cardFormatSubType={card_format_subtype}
          className={className}
          skipViewEvents={skipEvents}
        />

        <div className="flex justify-center mt-6 md:mt-5">
          {!showAllProducts && (products.length ?? 0) > 4 ? (
            isExpanded && cta_url && cta_label ? (
              ctaLink()
            ) : (
              <span
                role="button"
                ref={showMoreRef}
                className="underline cursor-pointer"
                onClick={handleShowButtonClick}
              >
                {isExpanded ? contractLabel : expandLabel}
              </span>
            )
          ) : (
            cta_url && cta_label && ctaLink()
          )}
        </div>
      </div>
    </>
  );
}
