import ProductCard from "@/components/ProductCard";
import type { ProductSummary } from "@/lib/productSummary/types";
import { FormatSubType } from "@/constants/tagular/main";
import { useLocale } from "next-intl";
import { getTranslatedProductType } from "./utils/getTranslatedProductType";

interface ProductCardListProps {
  products: ProductSummary[];
  skipViewEvents?: ProductSummary[];
  shouldScroll?: boolean;
  location?: string;
  cardFormatSubType?: FormatSubType;
  className?: string;
}

function ProductCardList({
  products,
  location,
  cardFormatSubType,
  className,
  shouldScroll = true,
  skipViewEvents = [],
}: ProductCardListProps) {
  const locale = useLocale();
  if (!products || products.length === 0) {
    return null;
  }

  const styles = `flex gap-4 py-4 ${shouldScroll ? "overflow-x-scroll" : "flex-wrap"} ${className}`;

  return (
    <div className={styles}>
      {products.map((product, index) => {
        return (
          <ProductCard
            key={`${location}-${product.productUuid}`}
            name={product.productName}
            href={`/${product.productSlug}`}
            imageUrl={product.productImageUrl}
            type={getTranslatedProductType(product.productType, locale)}
            attributes={product.attributes || []}
            partnerName={product.partnerName}
            partnerLogoUrl={product.partnerLogoUrl}
            location={location}
            index={index}
            subjects={product.attributes}
            formatSubtype={cardFormatSubType}
            skipViewEvent={skipViewEvents?.includes(product)}
            objectId={product.objectId}
            productSource={product.productSource}
            product={product.productCategory}
            uuid={product.productUuid}
            parentTitle={product.productName}
            activeRunKey={product.activeRunKey}
          />
        );
      })}
    </div>
  );
}

export default ProductCardList;
