const getSortedRefinementItems = (
  refinementItems: { isRefined: boolean; value: string; label: string }[],
) => {
  return refinementItems.sort((a, b) => {
    // Sort by `isRefined` first
    const isRefinedDiff = Number(b.isRefined) - Number(a.isRefined);
    if (isRefinedDiff !== 0) return isRefinedDiff;

    // Fallback to alphabetical sorting by `value`
    return a.value.localeCompare(b.value);
  });
};

export default getSortedRefinementItems;
